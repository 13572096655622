.App {
}

.App-body {
  background-color: #fff;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}

legend.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
}

hr {
  width: 100%;
}

.react-confirm-alert-overlay {
  padding: 10px;
}

.react-confirm-alert {
  max-width: 100%;
}

.react-confirm-alert-body {
  width: auto !important;
}
